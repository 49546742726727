@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.tab {
    transform: scale(0.5);
    position: relative;
    top: 27%;
    cursor: pointer;
}

.lltab {
    border-right: 3px solid gray;
    left: 40%;
}

.ltab {
    border-right: 3px solid gray;
}

.rtab {
    border-left: 3px solid gray;
}

.rrtab {
    border-left: 3px solid gray;
    right: 40%;
}

.nav {
    transform: scale(0.7);
    display: flex;
    flex-direction: row;
    margin: unset;
}


.slash {
    border-right: 2px solid gray;
    height: 14vh;
    transform: skew(-45deg);
    z-index: 5;
    position: absolute;
}

.circle {
    width: 25vh;
    height: 25vh;
    border: 2px solid gray;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buy {
    position: relative;
    right: 10%;
    transform: scale(0.5);
    cursor: pointer;
}

.sell {
    position: relative;
    left: 10%;
    transform: scale(0.5);
    cursor: pointer;
}

@media screen and (max-width: 1000px) {

    .circle {
        width: 20vh;
        height: 20vh;
    }
    .nav {
        transform: scale(0.45);
    }

    
}

