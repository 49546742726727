.main-container {
    min-height: 180vh;
}

.body {
    background-image: url(./assets/images/paper.png);
    background-size: 100% 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

body, html {
    height: 100%;
    margin: 0;
}