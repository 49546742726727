@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.headerTitle {
    transform: scale(0.6);
}

header {
    overflow: hidden;
    width: 100%;
    height: 27vh;
    background-image: url(../../assets/images/header.png);
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.btn {
    background-color: unset;
    border: none;
    position: absolute;
    right: -5%;
    top: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    font-size: 50px;
    transform: scale(0.3);
}

.active {
    text-decoration: underline;
}

@media screen and (max-width: 1000px) {
    .header {
        transform: scale(0.5);
    }
}

@media screen and (max-width: 700px) {

    .btn {
        right: 0%;
        top: 10%;
    }
    
}