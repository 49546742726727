@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

p {
    margin: 0;
}

.results {
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    display: grid;
    grid-template-columns: 10% 70% 10%;
    grid-auto-flow: row;
    justify-content: space-evenly;
}

.contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleImg {
    transform: scale(0.7);
}
.pattern {
    width: 100%;
}


.pattern3 {
    width: 100%;
}

/* .mapFrame {
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    z-index: 2;
    pointer-events: none; 
} */

.mapContainer {
    height: 100%;
    width: 100%;
    position: relative;
    /* top: 7vh; */
    /* min-height: 70vh; */
}

 .neighbourContainer {
    background-size: 100% 100%;
    font-size: 21px;
    color: black;
    height: 80vh;
    overflow: scroll;
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
    line-height: 1.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    margin-bottom: 10vh;
}

.highlight {
    height: 30vh;
}

.map {
    /* position: relative;
    bottom: 7vh; */
}

.demographic {
    margin-bottom: 30vh;
}

.frame {
    font-family: 'Share Tech Mono', monospace;
    font-size: 21px;
    color: black;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.mountain {
    transform: scale(0.6);
    position: absolute;
    bottom: -220%;
    left: -10%;
    pointer-events: none; 
}

.bird {
    position: absolute;
    top: 18%;
    left: 10%;
    pointer-events: none; 
    transform: scale(0.6);
}

.forest {
    transform: scale(0.7);
    position: absolute;
    top: 50%;
    right: -3%;
    pointer-events: none;  
}

/* .boat {
    transform: scale(0.6);
    position: absolute;
    top: 18%;
    left: 10%;
    pointer-events: none;
} */


@media screen and (max-width: 1000px) {

    .title {
        transform: scale(0.5);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .bird {
        display: none;
    }
}